<template>
  <div class="manage-bank-loan-detail">
    <div class="container fluid">
      <bank-loan-detail
        :downloadFileAPI="downloadFile"
        :getBankLoanAPI="bankLoanAPI.getBankLoan"
        :verifyLeadAPI="bankLoanAPI.verifyLead"
        :declineLeadAPI="bankLoanAPI.declineLead"
        :approveLAAPI="bankLoanAPI.approveLA"
        :rejectLAAPI="bankLoanAPI.rejectLA"
        :confirmLOSignedAPI="bankLoanAPI.confirmLOSigned"
      ></bank-loan-detail>
    </div>
  </div>
</template>

<script>
import { bankLoan as bankLoanAPI } from "@/api";
import BankLoanDetail from "@/components/GlobalComponents/Shared/Banker/BankLoanDetail";
import { downloadFile } from "@/utils/download";

export default {
  components: {
    BankLoanDetail
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      bankLoanAPI,
      downloadFile
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
